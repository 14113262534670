<template>
  <q-layout class="login" view="hHh lpR fFf">
    <q-page-container>
      <q-card class="my-card">
        <q-img
          :src="require('../../assets/logo.png')"
          spinner-color="primary"
          spinner-size="82px"
        />
        <q-card-section>
          <div class="text-h6">Sistema de Aprovações</div>
          <div class="text-subtitle2">Informe e-mail e senha para entrar</div>
        </q-card-section>
        <q-card-section>
          <div class="row">
            <div class="col">
              <q-input type="text" label="E-mail" v-model="email" />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <q-input
                :type="viewPass ? 'text' : 'password'"
                label="Senha"
                v-model="password"
              >
                <template v-slot:append>
                  <q-icon
                    :name="viewPass ? 'visibility_off' : 'visibility'"
                    @click="viewPass = !viewPass"
                  />
                </template>
              </q-input>
            </div>
          </div>
          <div class="row q-mt-lg justify-between">
            <div class="col-auto">
              <q-btn
                flat
                dense
                color="green"
                label="Esqueci a minha senha"
                @click="recoverPassword"
              />
            </div>
            <div class="col-auto">
              <q-btn
                color="green"
                icon="check"
                label="Entrar"
                @click="access"
              />
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-page-container>
  </q-layout>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      email: "",
      password: "",
      viewPass: false
    };
  },
  methods: {
    ...mapActions("users", ["login", "sendRecoverPasswordEmail"]),
    async access() {
      try {
        await this.login({
          email: String(this.email).trim(),
          password: this.password
        });
        this.$router.push({ name: "home" });
      } catch (error) {
        console.error(error);
      }
    },
    recoverPassword() {
      this.sendRecoverPasswordEmail(this.email);
    }
  },
  created() {
    if (process.env.NODE_ENV === "development") {
      this.email = "felipe.moreira@cicopal.com.br";
      this.password = "123456";
    }
  }
};
</script>

<style lang="scss">
.login {
  .my-card {
    margin: 0 auto;
    margin-top: 100px;
    width: 90vw;
    background: #f7f7f7;
    @media (min-width: 500px) {
      max-width: 500px;
    }
  }
}
</style>
